<template>
  <influencer-outreach-form
    :model="data.model"
    :is-loading="isLoading"
    @submit="handleSubmit"
  />
</template>

<script>
// Import child components
const InfluencerOutreachForm = () => import(/* webpackChunkName: "influencer-outreach-form" */ "@/components/crm/influencer-outreach/Form.vue")

// Export the SFC
export default {
  // Name of the SFC
  name: "InfluencerOutreachEdit",

  // Register children components
  components: {
    InfluencerOutreachForm
  },

  // Accept incoming data from parent
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  // Define local data variables
  data: () => ({
    // Whether we're making a network request or not
    isLoading: false
  }),

  // Define local method functions
  methods: {
    /**
     * Handle the submit event from child form view
     *
     * @returns {void}
     */
    async handleSubmit(formData) {
      // Otherwise, set a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Make the network request
      try {
        // Get the response
        const response = await axios({
          url: `/api/influencer-outreach/${this.data.model.id}`,
          method: "PUT",
          data: {
            name: formData.name,
            hashtags: formData.hashtags,
            platforms: Object.keys(formData.platforms).filter((key) => formData.platforms[key]),
            budget: formData.budgeting.budget,
            start_date: formData.budgeting.startDate,
            end_date: formData.budgeting.endDate,
            description: formData.budgeting.description,
            goal: formData.budgeting.goal,
            target_location: formData.targeting.location,
            target_gender: formData.targeting.gender,
            target_age_from: formData.targeting.age.from,
            target_age_to: formData.targeting.age.to,
            target_age_weightage: formData.targeting.age.weight,
            target_interests: formData.targeting.interests,
            target_text: formData.targeting.text
          }
        })

        // Show a success toast
        this.$store.dispatch("toasts/add", { text: "Campaign updated!" })

        // Update the model data in the store
        this.$store.dispatch("influencerOutreach/updateCampaign", this.data.model.id)
      } catch (error) {
        // Log the error
        logger({ type: "InfluencerOutreach/Update Error", error })

        // Show an error toast
        this.$store.dispatch("toasts/add", { text: error.response?.data?.message || "An error occurred, please try again!" })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  }
}
</script>
